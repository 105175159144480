
import { RevCardGrid, RevCategoryCard } from '@backmarket/design-system'

import { trackClick } from '@tracking/events'

import BlockTitle from '../private/BlockTitle'

export default {
  components: {
    BlockTitle,
    RevCategoryCard,
    RevCardGrid,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tracking: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    categoriesFormatted() {
      return this.categories.map((category) => ({
        ...category,
        description: category.description || category.tagline || '',
        image: category.image || {},
        link: category.link || {},
        target: category.link?.targetBlank ? '_blank' : '_self',
      }))
    },
  },

  methods: {
    sendTrackingData({ name }) {
      trackClick({
        ...this.tracking,
        name,
      })
    },
  },
}
